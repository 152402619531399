<template>

  <v-parallax src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="150px">
    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <h1 class="text-h4 font-weight-thin mb-4">
        <span>Política de devoluciones</span>
      </h1>
    </div>
  </v-parallax>

  <v-container fluid style="max-width: 680px" >

    <h2>Política de devoluciones de <v-btn class="pa-0" size="small" variant="text" href="https://dorsalshop.es" target="_blank">dorsalshop.es</v-btn></h2>
    <br>
    <p>Los plazos de devolución de los productos no personalizados se establecen en 14 días desde el momento de la compra, donde el consumidor podrá anular y devolver un pedido de una compra sin indicar el motivo y sin incurrir en ningún coste.</p>
    <br>
    <p>En el caso de los productos personalizados, establecemos un plazo de cancelación del pedido de una hora posterior del pedido. En este caso, y teniendo en cuenta que es un producto personalizado, tal y se recoge en la Ley 3/2014 del 27 de marzo en el artículo 103, punto C, el consumidor no podrá ejercer el derecho de desistimiento en el caso de que el suministro de bienes confeccionados sea conforme a las especificaciones del consumidor y usuario o claramente personalizados.</p>
    <br>
    <p>Para realizar cancelaciones de pedidos realizados a través de nuestra web puede tramitarlos solicitándolo bien por
      <v-btn class="pa-0" size="small" variant="text" href="https://wa.me/+34607763001" target="_blank">WhatsApp (607763001)</v-btn> o bien por <v-btn class="pa-0" size="small" variant="text" href="mailto:info@mychip.es" target="_blank">correo electrónico (info@mychip.es)</v-btn>.</p>

  </v-container>

</template>

<script>
import {defineComponent} from 'vue';
import axios from "axios";
import router from "@/router";

export default defineComponent({
  name: 'CompanyView',
  data: () => ({
  }),
  computed: {
    english() {
      return this.$store.getters.language == 'en'
    },
    spanish() {
      return this.$store.getters.language == 'es'
    },
    valencian() {
      return this.$store.getters.language == 'ca'
    },
  }
});
</script>
