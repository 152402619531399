<template>

  <v-progress-linear indeterminate :height="12" v-if="loading"></v-progress-linear>

  <v-parallax
      height="250"
      scale="1"
      :src="header_image"
  >

    <div class="d-flex flex-column fill-height justify-center align-center text-white">
      <span class="text-h4 text-uppercase font-weight-black text-center">
          {{ $t('slogan.adding') }}
          <span class="font-weight-regular text-lowercase" v-if="stats">
            <number :from="0" :to="$root.parse_distance(stats.distance, false)" :duration="1" easing="Power1.easeOut"/>
          </span>
          {{$t(`units.distance.${$root.units}`)}} {{ $t('slogan.together') }}
        </span>
      <template v-if="!stats">
        <v-icon size="x-small" icon="fas fa-spinner fa-spin"/>
      </template>
      <template v-else>
        <h5 class="subheading">
          <v-chip class="" variant="tonal" label size="small">
            <span><number :from="0" :to="stats.events" :duration="1"
                          easing="Power1.easeOut"/> {{ $tc('event', stats.events) }}</span>
          </v-chip>
          <v-chip class="ml-1" variant="tonal" label size="small" v-if="stats.users">
            <span><number class="ml-1" :from="0" :to="stats.users" :duration="1"
                          easing="Power1.easeOut"/>K usuarios</span>
          </v-chip>
          <v-chip class="ml-1" variant="tonal" label size="small" v-if="stats.registrations">
            <span>+<number class="" :from="0" :to="stats.registrations" :duration="1" easing="Power1.easeOut"/>K inscripciones</span>
          </v-chip>
          <v-chip class="ml-1" variant="tonal" label size="small" v-if="stats.finished">
            <span>+<number class="" :from="0" :to="stats.finished" :duration="1"
                           easing="Power1.easeOut"/>K finishers</span>
          </v-chip>
        </h5>
      </template>
      <v-btn variant="elevated" color="primary" size="small" class="mt-2" :to="{name: 'EventCalendar', params: {type: 'next'}}">
        <v-icon start icon="fa-solid fa-magnifying-glass" size="small"></v-icon>
        buscar eventos
      </v-btn>
    </div>
  </v-parallax>

  <v-container style="max-width: 960px;">

    <v-tabs
        v-model="tab"
        align-tabs="center"
        center-active
    >
      <v-tab value="past">
        <v-icon size="small" icon="fa-solid fa-backward-fast mr-2" :disabled="loading_past || events_past.length == 0"></v-icon>
        <span class="hidden-xs mr-1">ÚLTIMOS</span>RESULTADOS
      </v-tab>
      <v-tab value="live" v-if="live_tab_enabled">
        <v-icon v-if="live_tab_enabled" icon="fa-solid fa-circle fa-beat mr-2" color="red"></v-icon>
        <span class="hidden-xs mr-1">EN </span>DIRECTO ({{events_live.length}})
      </v-tab>
      <v-tab value="next" :disabled="loading_next">PRÓXIMOS<span class="hidden-xs ml-1"> EVENTOS</span>
        <v-icon size="small" icon="fa-solid fa-forward-fast ml-2"></v-icon>
      </v-tab>
    </v-tabs>
    <v-window disabled v-model="tab" class="mt-4" >
      <v-window-item v-for="type in ['past', 'live', 'next']" :key="type" :value="type">
        <v-row align-content="center">
          <template v-for="(e, idx) in get_events_by_type(type)">

            <v-col cols="12" sm="6" md="4" v-if="idx == random_ad_position">
              <AdSense :ad_slot="5164318843"></AdSense>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <EventCardV2 :e="e"></EventCardV2>
            </v-col>
          </template>
        </v-row>

        <v-row v-if="['past', 'next'].includes(type)">
          <v-col cols="12">
            <v-btn block v-if="type == 'past'" variant="flat" color="primary" :to="{name: 'EventResults', params: {type: 'past'}}">Mostrar más resultados</v-btn>
            <v-btn block v-if="type == 'next'" variant="flat" color="primary" :to="{name: 'EventCalendar', params: {type: 'next'}}">Ver todo el calendario</v-btn>
          </v-col>
        </v-row>


      </v-window-item>
    </v-window>

  </v-container>
</template>

<style scoped>
>>>.v-img__img--cover {
  /*filter: blur(3px)!important;*/
}
</style>

<script>
import {defineComponent} from 'vue';
import EventCard from "@/components/EventCard.vue";
import EventCardV2 from "@/components/EventCardV2.vue";
import AdSense from '../components/AdSense.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    EventCard,
    EventCardV2,
    AdSense
  },
  data: () => ({
    tab: 'next',
    loading_live: true,
    loading_next: true,
    loading_past: true,
    loading_stats: true,
    live_tab_enabled: false,
    events_live: [],
    events_next: [],
    events_past: [],
    stats: null,
    header_image: null,
    random_ad_position: Math.floor(Math.random() * (8 - 4 + 1) + 4),
    header_images: [
      'https://images.unsplash.com/photo-1456613820599-bfe244172af5', // Trail
      'https://images.unsplash.com/photo-1594882645126-14020914d58d', // Trail
      'https://images.unsplash.com/photo-1510078344547-e481316148ba',  // Trail
      'https://images.unsplash.com/photo-1610772965521-f5ed4e493e64',  // Trail
      'https://images.unsplash.com/photo-1489244094604-1758de9a6728',  // Trail
      'https://images.unsplash.com/photo-1502224562085-639556652f33', // Running
      'https://images.unsplash.com/photo-1552674605-db6ffd4facb5',    // Running
      'https://images.unsplash.com/photo-1502904550040-7534597429ae', // Running
      'https://images.unsplash.com/photo-1603102859961-64b17d43580d', // Running
      'https://images.unsplash.com/photo-1604748954134-457791b2ce9b', // BTT
      'https://images.unsplash.com/photo-1615406308854-4805ac35ef25', // BTT
      'https://images.unsplash.com/photo-1589100984317-79246528923c', // BTT
      'https://images.unsplash.com/photo-1499438075715-fc23ef376ab9', // Cycling
      'https://images.unsplash.com/photo-1501610862033-8787c0add568', // Cycling
    ],
    header_images_used: []
  }),
  created() {
    document.title = `${this.$root.app_name}`
    this.get_header_image()
    window.setInterval(() => {
      this.get_header_image()
    }, 5000);

    this.fetch_live()
    this.fetch_next()
    this.fetch_past()
    this.fetch_stats()
  },
  methods: {
    fetch_live() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {'status': ['waiting_for_start', 'live']}
      ).then((response) => {
        this.events_live = response.data
        if (this.events_live.length > 0) {
          this.tab = 'live'
          this.live_tab_enabled = true
        }
        this.loading_live = false
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_next() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            'status': ['registrations_not_opened', 'registrations_opened', 'registrations_full', 'registrations_closed'],
            "limit": 11,
          }
      ).then((response) => {
        this.events_next = response.data
        this.loading_next = false
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_past() {
      this.axios.post(
          `${process.env.VUE_APP_ROOT_API}/events`,
          {
            'status': ['finished_provisional', 'finished_official'],
            "limit": 11,
            "order": ["-date_start"]
          }
      ).then((response) => {
        this.events_past = response.data
        this.loading_past = false
      }).catch((error) => {
        console.error(error)
      })
    },
    fetch_stats() {
      this.axios.get(`${process.env.VUE_APP_ROOT_API}/stats`).then((response) => {
            this.stats = response.data
            this.stats.registrations = parseInt(this.stats.registrations / 1000)
            this.stats.finished = parseInt(this.stats.finished / 1000)
            this.stats.users = parseInt(this.stats.users / 1000)
            this.stats.distance = this.stats.distance
      }).catch((error) => {
        console.error(error)
      })
    },
    get_events_by_type(type) {
      if (type === 'live')
        return this.events_live
      if (type === 'next')
        return this.events_next
      if (type === 'past')
        return this.events_past
    },
    get_header_image() {
      let to_use = this.header_images[Math.floor(Math.random() * this.header_images.length)]

      this.header_image = to_use
    }
  },

  computed: {
    loading: {
      get: function () {
        return this.loading_live ||this.loading_past ||this.loading_next
      }
    },
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    },
    axios: {
      get: function () {
        return this.$root.$data.axios
      }
    }
  }

});
</script>
